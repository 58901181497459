import { useQueryClient } from "@tanstack/react-query";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import Loader from "react-loader-spinner";
import useCollection from "../../hooks/useCollection";
import useLocalStorage from "../../hooks/useLocalStorage";
import useStoreProducts from "../../hooks/useStoreProducts";
import { cn } from "../../lib/utils";
import Error500Page from "../../pages/500";
import { CART_KEY } from "../../services/frontend/StorageKeys";
import StorageManager from "../../services/frontend/StorageManager";
import { initialCart } from "../../services/ObjectsInitialValues";
import Basket from "../Cart/Basket";
import EmptyStore from "../EmptyStore";
import LoaderComponent from "../Loader";
import NavBar from "../Navbar";
import StoreCollections from "../StoreCollections";
import StoreItem from "../StoreItem";
import RestorableList from "./RestorableList";
import "resize-observer-polyfill";

export const ALL_PRODUCTS_OBJECT = {
  name: "all products",
  _id: "all",
};

export const CollectionHome = ({ store }) => {
  const router = useRouter();

  const { collection: queryCollection } = router.query;
  const [selectedCollection, setSelectedCollection] = useState(ALL_PRODUCTS_OBJECT);
  const [lastEl, setLastEl] = useState(null);
  const { ref, inView } = useInView({
    threshold: 0,
  });
  const queryClient = useQueryClient();

  const {
    data: productData,
    isLoading: isProductsLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    error: productError,
  } = useStoreProducts(store.id, selectedCollection?._id);

  const {
    data: collectionsData,
    isLoading: isCollectionsLoading,
    error: collectionsError,
  } = useCollection(store.id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const products = productData?.pages.flatMap((page) => page.products) || [];
  const collections = collectionsData || [];
  const [cart, setCart] = useLocalStorage(CART_KEY, initialCart);
  const [showCart, setShowCart] = useState(false);

  const handleShowCart = useCallback((boolVal = false) => {
    setShowCart(boolVal);
  }, []);

  useEffect(() => {
    if (queryCollection) {
      const foundCollection = collections.find((c) => {
        return c.name.trim().toLowerCase() === queryCollection.trim().toLowerCase();
      });
      setSelectedCollection(foundCollection);
    }
  }, [collections]);

  const setFilter = useCallback(
    (collection) => {
      queryClient.invalidateQueries(["storeProducts", store.id, collection]);
      queryClient.removeQueries(["storeProducts", store.id, selectedCollection]);
      setSelectedCollection(collection);
      router.push(
        {
          pathname: router.pathname,
          query: {
            ...router.query,
            collection: collection.name === "all products" ? "" : collection.name,
          },
        },
        undefined,
        { shallow: true },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [router],
  );

  useEffect(() => {
    let timer;
    if (inView && hasNextPage && !isFetchingNextPage) {
      timer = setTimeout(() => {
        fetchNextPage();
      }, 100);
    }
    return () => {
      window.clearTimeout(timer);
    };
  }, [inView, hasNextPage, isFetchingNextPage, fetchNextPage]);

  useEffect(() => {
    if (!products.length) return;
    const [lastElement] = products.slice(-1);
    setLastEl(lastElement.id);
  }, [products]);

  if (isCollectionsLoading) return <LoaderComponent />;
  if (collectionsError || productError) return <Error500Page />;
  return (
    <div className="w-full min-h-[calc(100vh-268px)] mt-[38px] px-[22px] scrollbar-none">
      <Basket
        setRefresh={() => {}}
        isBasketOpen={showCart}
        setCart={setCart}
        cartData={cart}
        StorageManager={StorageManager}
        CART_KEY={CART_KEY}
        handleShowCart={handleShowCart}
        store={store}
      />
      <NavBar
        cartActive={cart.products.length > 0}
        handleShowCart={handleShowCart}
        hideInAdvance={false}
        homeActive={false}
        store={store}
        storeName={store?.storeName || ""}
      />
      {collections && collections.length > 0 ? (
        <StoreCollections
          collections={collections}
          setFilter={setFilter}
          selectedCollection={selectedCollection}
        />
      ) : (
        <div className="h-5"></div>
      )}
      {products.length > 0 ? (
        <div className="grid mt-[10px] gap-[11px_22px] relative pt-16">
          <RestorableList>
            {React.Children.toArray(
              products.map((_, index) => {
                const startIndex = index * 2;
                const endIndex = startIndex + 2;
                const rowProducts = products.slice(startIndex, endIndex);
                if (!rowProducts.length) return null;
                return (
                  <div className="grid grid-cols-2 gap-4">
                    {React.Children.toArray(
                      rowProducts.map((product) => (
                        <div ref={lastEl === product.id ? ref : null}>
                          <StoreItem
                            cohort={store.cohort.includes(2) ? store.cohort : null}
                            product={product}
                            top={index === 0}
                          />
                        </div>
                      )),
                    )}
                  </div>
                );
              }),
            )}
          </RestorableList>
        </div>
      ) : (
        !isProductsLoading && <EmptyStore store={store} />
      )}
      {/* Loader for next page */}
      {(isFetchingNextPage || isProductsLoading) && (
        <div
          className={cn(
            "w-full flex justify-center",
            isProductsLoading && "h-[100vw] items-center",
          )}
        >
          <Loader type="Oval" color="#ccc" height={40} width={40} />
        </div>
      )}
    </div>
  );
};
