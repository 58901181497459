import { useRouter } from "next/router";
import React, { useMemo, useState, useEffect } from "react";
import Footer from "../Footer/Footer";
import { CollectionHome } from "./CollectionHome";
import FeaturedHome from "./FeaturedHome";
import { ScrollProvider } from "./ScrollPositionProvider";

export const MainHome = ({ store, hideHeroMedia }) => {
  const router = useRouter();
  const homeActive = !!store?.homePageEnabled && !hideHeroMedia;

  const updateHomeActive = () => router.push("/shop");

  return (
    <main className="w-screen max-w-[var(--max-width)] overflow-hidden transition-[max-height] duration-200 flex-auto">
      {homeActive ? (
        <FeaturedHome
          store={store}
          updateHomeActive={updateHomeActive}
          heroMedia={store?.homePage?.heroMedia.url}
          heroMediaImages={store?.homePage?.heroMediaImages}
          heroMediaType={store?.homePage?.heroMediaType}
          heroTitle={store.homePage.heroTitle ? store.homePage.heroTitle : store.storeName}
        />
      ) : (
        <ScrollProvider>
          <CollectionHome store={store} />
        </ScrollProvider>
      )}
      <Footer />
    </main>
  );
};
