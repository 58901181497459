import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { Carousel } from "react-responsive-carousel";
import useLocalStorage from "../../hooks/useLocalStorage";
import { CART_KEY } from "../../services/frontend/StorageKeys";
import StorageManager from "../../services/frontend/StorageManager";
import { publicServerEndpoint } from "../../services/helper";
import { initialCart } from "../../services/ObjectsInitialValues";
import Basket from "../Cart/Basket";
import NavBar2 from "../NavBar2/NavBar2";
import styles from "./featured-home.module.css";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import FeaturedCollectionWithProd from "./FeaturedCollectionWithProd";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const FeaturedHome = ({
  store,
  updateHomeActive,
  heroMedia,
  heroMediaImages,
  heroMediaType,
  heroTitle,
}) => {
  const [cart, setCart] = useLocalStorage(CART_KEY, initialCart);
  const [collections, setCollections] = useState([]);
  const [showCart, setShowCart] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const fetchCollections = async () => {
      try {
        let sortedCollections = [];
        if (!sortedCollections.length) {
          const url = `${publicServerEndpoint}/v2/collection/store/${store.id}/?source=featured`;
          const response = await fetch(url);
          const data = await response.json();
          sortedCollections = data;
          console.log(url, " -> ", data);
        }

        const fetchedCollections = sortedCollections.map((collection) => ({
          collection,
          products: [],
        }));
        setCollections(fetchedCollections);

        fetchedCollections.forEach(async (collection, index) => {
          try {
            const productsUrl = `${publicServerEndpoint}/v3/product/store/${store.id}/collectionv2?page=1&limit=10&source=web&collectionId=${collection.collection._id}`;
            const productsResponse = await fetch(productsUrl);
            const productsData = await productsResponse.json();

            if (Array.isArray(productsData.data.docs) && productsData.data.docs.length > 0) {
              setCollections((prevCollections) => {
                const updatedCollections = [...prevCollections];
                updatedCollections[index].products = productsData.data.docs;
                return updatedCollections;
              });
            }
          } catch (error) {
            console.error(
              `Error fetching products for Collection ${collection.collection.name}:`,
              error,
            );
          }
        });
      } catch (error) {
        console.error("Error fetching collections:", error);
      }
    };
    fetchCollections();
  }, [store.id, store.collections]);

  return (
    <div>
      <div
        className={`relative w-full ${heroMediaType === "image" ? "h-full" : "h-screen"} flex justify-center items-center overflow-hidden`}
      >
        <Basket
          setRefresh={() => {}}
          isBasketOpen={showCart}
          setCart={setCart}
          cartData={cart}
          StorageManager={StorageManager}
          CART_KEY={CART_KEY}
          handleShowCart={setShowCart}
          store={store}
        />
        <NavBar2
          cartActive={true}
          hideInAdvance={false}
          homeActive={true}
          storeName={heroTitle}
          handleShowCart={setShowCart}
          store={store}
        />
        {heroMediaType === "image" ? (
          <Carousel
            autoPlay
            infiniteLoop
            showThumbs={false}
            showArrows={false}
            showStatus={false}
            className={styles.carouselImageContainer}
            onClickItem={() => router.push("/shop")}
          >
            {heroMediaImages.map((imageUrl) => {
              const imageName = imageUrl.substring(imageUrl.lastIndexOf("/") + 1);
              const imgurlNew = store.cohort?.includes(1)
                ? `https://ik.imagekit.io/dm2asset/dm2assetfull/${imageName}`
                : `https://dm2buy-resize-dynamic-cebdcaefgydgh6hu.z02.azurefd.net/dm2buy/${imageName}`;
              return (
                <div key={imageName}>
                  <img
                    src={imgurlNew}
                    alt={imageName}
                    className="w-full h-full object-cover pb-8"
                  />
                </div>
              );
            })}
          </Carousel>
        ) : (
          <ReactPlayer
            url={heroMedia}
            playing
            loop
            muted
            playsinline
            width="100%"
            height="100%"
            className={styles.carouselVideoContainer}
            onClick={() => router.push("/shop")}
          />
        )}
      </div>
      <div className="w-full flex flex-col items-center mt-2.5 z-10">
        <button
          onClick={() => updateHomeActive(false)}
          className="w-36 h-10 rounded-md bg-black text-white flex justify-center items-center text-sm"
        >
          SHOP NOW
        </button>
      </div>
      {collections.length > 0
        ? collections.map((collection) => (
            <FeaturedCollectionWithProd
              cohort={store.cohort}
              key={collection.collection._id}
              collectionName={capitalizeFirstLetter(
                collection.collection.name ? collection.collection.name : "",
              )}
              productsData={collection.products}
            />
          ))
        : null}
    </div>
  );
};

export default FeaturedHome;
