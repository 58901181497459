import { useLayoutEffect, useRef, useMemo, useEffect } from "react";
// eslint-disable-next-line no-undef
const { WindowVirtualizer } = require("virtua");

const RestorableList = ({ children }) => {
  const cacheKey = "window-list-cache-1";
  const ref = useRef(null);

  // Add error handling for ResizeObserver
  useEffect(() => {
    const handleError = (error) => {
      if (error.message.includes("ResizeObserver")) {
        error.stopPropagation();
        return false;
      }
    };
    window.addEventListener("error", handleError);
    return () => window.removeEventListener("error", handleError);
  }, []);

  const [offset, cache] = useMemo(() => {
    let serialized;
    try {
      serialized = sessionStorage.getItem(cacheKey);
    } catch (error) {
      console.error("Failed to access sessionStorage:", error);
      serialized = null;
    }
    if (!serialized) return [];
    try {
      return JSON.parse(serialized);
      // eslint-disable-next-line no-unused-vars
    } catch (e) {
      return [];
    }
  }, []);

  useLayoutEffect(() => {
    if (!ref.current) return;
    const handle = ref.current;
    window.scrollTo(0, offset ?? 0);
    sessionStorage.removeItem(cacheKey);

    let scrollY = 0;
    const onScroll = () => {
      scrollY = window.scrollY;
    };
    window.addEventListener("beforeunload", () => {
      sessionStorage.removeItem(cacheKey);
    });
    window.addEventListener("scroll", onScroll);
    onScroll();

    return () => {
      window.removeEventListener("scroll", onScroll);
      // Use stored window.scrollY because it may return 0 in useEffect cleanup
      sessionStorage.setItem(cacheKey, JSON.stringify([scrollY, handle.cache]));
    };
  }, [offset]);

  return (
    <WindowVirtualizer ref={ref} cache={cache}>
      {children}
    </WindowVirtualizer>
  );
};

export default RestorableList;
