import Link from "next/link";
import React from "react";
import StoreItem from "../StoreItem";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const FeaturedCollectionWithProd = ({ cohort, collectionName, productsData }) => {
  return (
    <div className="m-5">
      <h2 className="text-2xl mt-[50px] mb-[30px] mx-[10px] text-center font-bold">
        {capitalizeFirstLetter(collectionName ? collectionName : "")}
      </h2>
      <div className="flex flex-row overflow-x-auto overflow-y-hidden gap-4 whitespace-nowrap hide-scrollbar">
        {productsData.map((product) => (
          <StoreItem
            cohort={cohort}
            usedForCollectionhome={true}
            key={product.id}
            product={product}
            pageSize={productsData.length}
          />
        ))}
      </div>
      <Link href={`/shop?collection=${collectionName.replace(/ /g, "+")}`} passHref>
        <a className="w-[90px] block text-center my-2 mx-auto bg-white text-black py-2 px-4 text-sm border border-gray-600 transition duration-300 ease-in-out hover:bg-gray-600 hover:text-white">
          View All
        </a>
      </Link>
    </div>
  );
};

export default FeaturedCollectionWithProd;
