import React from "react";

const CollectionFilter = ({ collection, selectedCollection, setFilter }) => {
  //console.log("CollectionFilter ", collection.name, selectedCollection.name);
  const isSelected = collection?.name === selectedCollection?.name;
  const handleClick = () => {
    if (!isSelected) {
      console.log("set filter ", collection);
      setFilter(collection);
    }
  };

  return (
    <div
      className={`m-[4px_5px_5px_4px] px-5 py-[4px] rounded-[50px] text-[13px] flex justify-center items-center tracking-[1px] whitespace-nowrap select-none capitalize ${
        isSelected ? "bg-black text-white" : "bg-[#f5f5f5] text-black"
      }`}
      onClick={handleClick}
    >
      {collection.name ? collection.name : "All Products"}
    </div>
  );
};

export default CollectionFilter;
