import { useMemo, Children } from "react";
import CollectionFilter from "./CollectionFilter";
import { ALL_PRODUCTS_OBJECT } from "./Home/CollectionHome";

const StoreCollections = ({ collections, setFilter, selectedCollection }) => {
  const storeCollections = useMemo(() => {
    const list = [];
    list.push(
      <CollectionFilter
        key={"initial"}
        collection={ALL_PRODUCTS_OBJECT}
        selectedCollection={selectedCollection}
        setFilter={setFilter}
      />,
    );
    const collectionList = Children.toArray(
      collections.map((collection) => (
        <CollectionFilter
          key={collection._id}
          collection={collection}
          selectedCollection={selectedCollection}
          setFilter={setFilter}
        />
      )),
    );

    return list.concat(collectionList);
  }, [collections, selectedCollection, setFilter]);
  return (
    <div className="relative top-10 left-0 right-0 flex leading-8 mt-[5px] px-0 overflow-x-auto overflow-y-hidden max-w-[500px] h-14 scroll-smooth z-10 hide-scrollbar">
      {collections.length ? storeCollections : null}
    </div>
  );
};
export default StoreCollections;
