import Image from "next/image";
import React from "react";

const EmptyStore = ({ store }) => (
  <div className="w-full flex items-center justify-center">
    <div className="w-[calc(var(--max-width)-200px)] flex flex-col justify-center items-center mt-[10vh]">
      <Image className="mt-12" src="/smiley.png" width="96" height="96" alt="Smiley" />
      <div className="mt-5 text-center text-[28px] font-bold text-[#1c3142]">
        Oh deer, it’s empty!
      </div>
      <div className="mt-2 text-center text-[16px] italic text-[#1c3142] leading-[1.5]">
        {store?.store?.storeName} haven't added any
        <br /> products to their shop yet.
        <br /> If you'd like to see them here, we'd
        <br /> love for you to drop them an invite.
      </div>
      <a
        href={`instagram://user?username=${store?.store?.contactInfo?.instagramHandle}`}
        className="mt-6 px-14 py-2.5 border border-black bg-black text-white rounded-lg hover:cursor-pointer"
      >
        INVITE
      </a>
    </div>
  </div>
);

export default EmptyStore;
