import { useInfiniteQuery } from "@tanstack/react-query";
import { publicServerEndpoint } from "../services/helper";

const fetchProducts = async ({ queryKey, pageParam = 1 }) => {
  const [, storeId, collection] = queryKey;
  let url = `${publicServerEndpoint}/v3/product/store/${storeId}/collectionv2?page=${pageParam}&limit=${20}&source=web`;
  if (collection && collection !== "all") {
    url += `&collectionId=${collection}`;
  }

  const response = await fetch(url);

  if (!response.ok) {
    throw new Error("Failed to fetch products");
  }

  const data = await response.json();

  return {
    products: data.data.docs,
    nextPage: pageParam + 1,
    previousPage: pageParam > 1 ? pageParam - 1 : null,
    hasNextPage: data.data.hasNextPage,
  };
};

const useStoreProducts = (storeId, collection) => {
  return useInfiniteQuery(["storeProducts", storeId, collection], fetchProducts, {
    getNextPageParam: (lastPage) => (lastPage.hasNextPage ? lastPage.nextPage : undefined),
    cacheTime: 30 * 1000,
    staleTime: 30 * 1000,
    keepPreviousData: true,
    enabled: !!storeId,
  });
};

export default useStoreProducts;
