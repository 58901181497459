import { useQuery } from "@tanstack/react-query";
import { publicServerEndpoint } from "../services/helper";

const fetchCollections = async (storeId) => {
  const response = await fetch(`${publicServerEndpoint}/v2/collection/store/${storeId}`);
  if (!response.ok) {
    throw new Error("Failed to fetch collections");
  }
  console.log("response ", response);
  return response.json();
};

const useCollection = (storeId) => {
  return useQuery(["collections", storeId], () => fetchCollections(storeId), {
    enabled: !!storeId,
  });
};

export default useCollection;
