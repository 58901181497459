import Image from "next/image";
import React, { useEffect, useState } from "react";
import EllipsisText from "react-ellipsis-text";
import { useScrollDirection } from "react-use-scroll-direction";

const NavBar2 = (props) => {
  const [direction, setDirection] = useState("");
  const { isScrollingUp, isScrollingDown } = useScrollDirection();

  useEffect(() => {
    isScrollingDown && setDirection("down");
    isScrollingUp && setDirection("up");
  }, [isScrollingDown, isScrollingUp]);

  const checkoutShouldHide = () => {
    if (typeof window !== "undefined" && window.scrollY > 120) {
      return true;
    }
    return false;
  };

  const hasBanner = props.store?.storeBanner?.text ?? false;

  return (
    <header
      className={`fixed left-1/2 top-0 flex w-full max-w-screen-xl translate-x-[-50%] items-center justify-between px-6 py-2 z-50 transition-all duration-300 h-[4.5rem] ${
        hasBanner ? "top-20" : "top-0"
      } ${direction === "up" ? "opacity-100" : ""} ${
        direction === "down" && checkoutShouldHide() ? "-top-16 opacity-0" : ""
      }`}
    >
      <div className="relative flex w-full items-center justify-center z-10">
        <h2
          className="inline-block font-lato text-lg font-bold text-white text-center cursor-pointer"
          onClick={() => (window.location.href = window.location.origin)}
        >
          <EllipsisText text={props.storeName} length={25} tail="..." />
        </h2>
        {props.cartActive && !props.hideInAdvance && (
          <span className="absolute right-0 z-10">
            <Image
              onClick={() => props.handleShowCart(true)}
              src="/cart-icon-white.svg"
              width={21}
              height={21}
              alt="Cart"
            />
          </span>
        )}
      </div>
    </header>
  );
};

export default NavBar2;
