import Lottie from "lottie-react";
import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";

import { LazyLoadImage } from "react-lazy-load-image-component";
import LinesEllipsis from "react-lines-ellipsis";

import * as animationData from "./shimmer.json";

// Browser-compatible path parsing
const parseFileName = (url) => {
  if (!url) return "";
  const parts = url.split("/");
  const filename = parts[parts.length - 1].split("?")[0];
  return {
    name: filename.substring(0, filename.lastIndexOf(".")),
    ext: filename.substring(filename.lastIndexOf(".")),
  };
};

const StoreItem = ({ usedForCollectionhome, product, cohort }) => {
  const [imageLoaded, setImageLoaded] = useState(false);
  const [fallbackSet, setFallbackSet] = useState(false);
  const [imageName, setImageName] = useState(null);
  const router = useRouter();

  const getProductPrice = (price, mrp = "") => {
    if (!price) return;
    if (mrp) {
      let strikeOutMRP = (
        <span className="text-sm font-light line-through opacity-60">{`${String.fromCharCode(0x20b9)}${mrp}`}</span>
      );
      return (
        <>
          {`${String.fromCharCode(0x20b9)}${price}`} {strikeOutMRP}
        </>
      );
    } else {
      return `${String.fromCharCode(0x20b9)}${product.price}`;
    }
  };

  const getImageUrl = () => {
    if (!imageName) return "";
    const imageurl =
      cohort && (cohort.includes(1) || cohort.includes(2))
        ? `https://ik.imagekit.io/dm2asset/${imageName}.jpg`
        : `https://dm2buy-resize-dynamic-cebdcaefgydgh6hu.z02.azurefd.net/dm2buy/${imageName}.jpg`;
    return imageurl;
  };

  useEffect(() => {
    const urlPath =
      product.productPhotos && product.productPhotos[0]
        ? // eslint-disable-next-line no-undef
          new URL(product.productPhotos[0]).pathname
        : "";

    const extractedImageName = parseFileName(urlPath).name;
    setImageName(extractedImageName);
  }, [product.productPhotos]);

  const checkStock = () => {
    let stockAvailable = false;
    if (product.variantOptions && product.variantOptions.length) {
      product.variantOptions.forEach((variant) => {
        if (variant?.availableStock > 0) {
          stockAvailable = true;
        }
      });
    } else {
      return product.availableStock;
    }
    return stockAvailable;
  };

  // Calculate dynamic widths based on collection home usage
  const imageWidthClass = usedForCollectionhome
    ? "w-[calc((var(--max-width)/1.5)-50px)] h-[calc((var(--max-width)/1.5)-50px)]"
    : "w-[calc((100vw/2)-33px)] h-[calc((100vw/2)-33px)] md:w-[calc((520px/2)-33px)] md:h-[calc((520px/2)-33px)]";

  const itemWidthClass = usedForCollectionhome
    ? "max-w-[calc((var(--max-width)/1.5)-50px)] mb-3"
    : "max-w-[calc((100vw/2)-33px)] mb-8 md:max-w-[calc((520px/2)-33px)]";

  if (!product) return "";
  return (
    <div
      href={`/product/${product.id}`}
      className={`flex justify-start items-start flex-col ${itemWidthClass}`}
      onClick={() => {
        router.push(`/product/${product.id}`);
      }}
    >
      <div>
        <div>
          {product && product?.productPhotos[0] && (
            <div className="relative rounded-lg">
              {!checkStock(product) && (
                <div className="absolute h-[98%] flex justify-center rounded-lg items-center w-full font-bold font-lato text-2xl text-[#ff3333] z-10">
                  <p className="m-0 p-0 tracking-[3px]">SOLD</p>
                </div>
              )}
              {!imageLoaded && (
                <div className={`rounded-lg ${imageWidthClass}`}>
                  <Lottie
                    className="rounded-lg border"
                    animationData={animationData.default || animationData}
                    loop={true}
                    autoplay={true}
                    rendererSettings={{
                      preserveAspectRatio: "xMidYMid slice",
                    }}
                  />
                </div>
              )}
              <LazyLoadImage
                style={
                  !imageLoaded
                    ? {
                        visibility: "hidden",
                        height: "0px !important",
                        width: "0px !important",
                        zIndex: 1,
                        position: "absolute",
                      }
                    : {}
                }
                onLoad={() => {
                  setImageLoaded(true);
                }}
                onError={(e) => {
                  if (!fallbackSet) {
                    // Handle image load error by setting a fallback URL
                    e.target.src = `${product?.productPhotos[0]}`;
                    setFallbackSet(true);
                  }
                }}
                className={`rounded-lg object-cover ${imageWidthClass} max-w-[none]`}
                src={getImageUrl(imageName, cohort)}
                unoptimized="true"
                objectfit="cover"
                alt={product.name}
              />
            </div>
          )}
        </div>

        <div className="w-full flex flex-col py-1">
          <div className="text-base mb-1 text-[#1c3142] opacity-80 font-normal font-roboto">
            {product && (
              <LinesEllipsis
                text={
                  usedForCollectionhome
                    ? product.name.length > 20
                      ? `${product.name.substring(0, 20)}...`
                      : product.name
                    : product.name
                }
                maxLine="2"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            )}
          </div>
          <div className="text-base font-bold text-[#1c3142] font-roboto">
            {product && getProductPrice(product.price, product.mrp)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default StoreItem;
